import "./App.css";

const url = "http://localhost:3000/katalogos.pdf";

function App() {
  return (
    <div className="App">
      <div className="logo">
        <img src="logo1.png" alt="" />
      </div>
      <span className="address">Ταξιαρχών 113, Κορυδαλλός</span>
      <a href={url} className="link link-header">
        <span>ΤΙΜΟΚΑΤΑΛΟΓΟΣ</span>
      </a>
      <a href="tel:+306938268250" className="link">
        <img src="telephone.svg" className="link__logo" alt="" />
        <span>ΚΑΛΕΣΤΕ ΜΑΣ</span>
      </a>
      <a
        href="https://www.e-food.gr/delivery/ksanthi/makao-specialty-goods"
        className="link"
      >
        <img src="efood.svg" className="link__logo" alt="" />
        <span>E-FOOD online</span>
      </a>
      <a href="https://www.instagram.com/hola_coffee.brunch/" className="link">
        <img src="instagram.svg" className="link__logo" alt="" />
        <span>INSTAGRAM</span>
      </a>
      <a href="https://facebook.com/100086621286587/" className="link">
        <img src="facebook.svg" className="link__logo" alt="" />
        <span>FACEBOOK</span>
      </a>
      <a href="mailto:holacaf22@gmail.com" className="link">
        <img src="mail.svg" className="link__logo" alt="" />
        <span>ΕΠΙΚΟΙΝΩΝΙΑ</span>
      </a>
      <a href="https://g.co/kgs/mTTjhg" className="link">
        <img src="map.svg" className="link__logo" alt="" />
        <span>ΤΟΠΟΘΕΣΙΑ</span>
      </a>
    </div>
  );
}

export default App;
